<template>
  <div class="joinBoxs">
    <mobileHeader />
    <div class="joinbanner">
      <img src="../../assets/m_joinBG.jpg" />
      <div
        class="banner-title"
        :class="{ bannerTitleB: $i18n.locale === 'en-US' }"
      >
        {{ $t("lang.joinUs.joinTitle") }}
      </div>
    </div>
    <div class="joincontain">
      <div class="joincontent">
        <div class="joinLeft">
          <div class="joinTitle">{{ $t("lang.joinUs.joinTitle") }}</div>
          <img src="../../assets/join1.png" />
        </div>
        <div class="joinRight">
          <div class="joinPart">
            <div class="joinName">
              {{ $t("lang.joinUs.addressTitle")
              }}{{ $t("lang.aboutUs.symbol") }}
              <span class="joinP">{{ $t("lang.joinUs.address") }}</span>
            </div>
          </div>
          <div class="joinPart">
            <div class="joinName">
              {{ $t("lang.joinUs.bemailTitle") }}{{ $t("lang.aboutUs.symbol") }}
              <span class="joinP">business@one-lime.com</span>
            </div>
          </div>
          <div class="joinPart">
            <div class="joinName">
              {{ $t("lang.joinUs.remailTitle") }}{{ $t("lang.aboutUs.symbol") }}
              <span class="joinP">resume@one-lime.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="joindetail"
      :style="{ display: $i18n.locale === 'en-US' ? 'none' : 'block' }"
    >
      <div class="operateBox">
        <div class="operateTitle">运营类岗位</div>
        <div class="operatelist">
          <div class="cardlist">
            <div class="names">亚马逊运营</div>
            <div class="descibe">职位描述</div>
            <div class="desclist">
              <div class="details">
                1、负责亚马逊销售运营工作，账号日常运营，店铺管理及维护；
              </div>
              <div class="details">
                2、负责上架新产品，编辑和优化产品信息，通过各种方式推广店铺和产品；
              </div>
              <div class="details">
                3、监控库存状况，及时订货和补货，定时清理库存；
              </div>
              <div class="details">
                4、处理客户邮件及查询，提供售前售中售后客户服务、提高Review的质量和数量；
              </div>
              <div class="details">
                5、及时跟踪订单信息，处理产品以及后台中差评、客户投诉，对客户的退换货进行跟踪妥善处理；
              </div>
              <div class="details">
                6、负责listing前期的文案撰写及上架，跟进图片制作以及A+页面的规划；
              </div>
              <div class="details">
                7、密切监控listing的健康状况，确保listing正常展示，同时为顾客提供客户服务，解决顾客的问题，提高评论的质量和数量；
              </div>
              <div class="details">8、完成上级领导交办的其他任务。</div>
            </div>
            <div class="descibe" style="margin-top: 40px">任职要求</div>
            <div class="desclist">
              <div class="details">
                1、学历大专及以上，英语4级及以上，有过管理亚马逊团队的经验且有爆款打造经验者优先，亚马逊操作经验1年以上；
              </div>
              <div class="details">
                2、精通亚马逊规则，有清晰的电子商务项目运营思路，能针对不同业务目标制定有效的运营策略；
              </div>
              <div class="details">
                3、策划及沟通能力强，有较强的创新与市场分析、营销、推广能力，分析与解决问题的能力；
              </div>
              <div class="details">
                4、有团队合作精神和执行能力，性格开朗，责任心强，为人诚信正直，有耐心
              </div>
              <div class="details">
                5、具备较强的服务意识和良好的人际沟通、协调能力，抗压能力强，能够适应高效率的工作环境。
              </div>
            </div>
          </div>
          <div class="cardlist">
            <div class="names">跨境电商运营</div>
            <div class="descibe">职位描述</div>
            <div class="desclist">
              <div class="details">
                1、了解amazon/ebay/sears等平台规则和操作要点；
              </div>
              <div class="details">2、调研市场需求，根据需求挑选产品；</div>
              <div class="details">3、产品上传，优化和活动提报；</div>
              <div class="details">4、产品备货及数据整理；</div>
              <div class="details">5、竞争对手分析。</div>
            </div>
            <div class="descibe" style="margin-top: 40px">任职要求</div>
            <div class="desclist">
              <div class="details">1、大专以上学历，英语四级以上优先；</div>
              <div class="details">
                2、思维活跃，善于独立思考，积极主动性高，执行力较强，半年以上相关经验优先；
              </div>
              <div class="details">
                3、该岗位提供专业培训，导师一对一指导，职位晋升快。
              </div>
              <div class="details">4、欢迎优秀应届生投递简历</div>
            </div>
          </div>
          <div class="cardlist">
            <div class="names">独立站运营</div>
            <div class="descibe">职位描述</div>
            <div class="desclist">
              <div class="details">
                1、负责独立站（Shopify等）账号日常运营，熟悉独立站建站流程，能独立完成网站建设，店铺管理及维护，制定营销计划；
              </div>
              <div class="details">
                2、负责产品上下架、推广、发布、信息更新，优化店铺及产品排名；
              </div>
              <div class="details">
                3、根据产品的推广情况，制定合理的推广计划及效果评估分析；
              </div>
              <div class="details">
                4、根据部门发展方向，通过对所负责的网站进行优化、所负责的商品进行定价、策划适当的营销方案；
              </div>
              <div class="details">
                5、调节毛利率、毛利额等关键性指标，不断提高网站的盈利水平；
              </div>
              <div class="details">6、提供新品开发需求，运营基础数据提供；</div>
              <div class="details">7、上级安排的其他工作。</div>
            </div>
            <div class="descibe" style="margin-top: 40px">任职要求</div>
            <div class="desclist">
              <div class="details">
                1、大专及以上学历，电子商务、英语、设计专业优先，半年以上独立站（shopify）运营经验；
              </div>
              <div class="details">
                2、英语4级及以上，熟练的英语读写能力，熟练撰写英文文案；
              </div>
              <div class="details">3、熟练应用office办公软件；</div>
              <div class="details">4、思维活跃，有求知欲。</div>
            </div>
          </div>
          <div class="cardlist">
            <div class="names">电商运营主管/经理</div>
            <div class="descibe">职位描述</div>
            <div class="desclist">
              <div class="details">
                1、针对公司战略，制定年季月度销售规划和营销方案；
              </div>
              <div class="details">
                2、负责官方大型活动统筹，线上推广创意及效果优化，监管团队执行以及活动后期数据统计；
              </div>
              <div class="details">
                3、整合商家/平台资源，对店铺数据/业绩直接负责，形成报表提出改进策略；
              </div>
              <div class="details">
                4、负责跟进行业竞店/竞品，定期做市场分析并掌握最新行业动态；
              </div>
              <div class="details">5、负责运营团队的日常管理工作；</div>
            </div>
            <div class="descibe" style="margin-top: 40px">任职要求</div>
            <div class="desclist">
              <div class="details">
                1、3 年/5 年以上电商运营经验;带 5 人以上团队，10 人最佳；
              </div>
              <div class="details">2、年销售额超 5 千万；</div>
              <div class="details">
                3、有两年以上京东/天猫/拼多多店铺工作经验和品牌旗舰店工作经验；
              </div>
              <div class="details">
                4、具有较强的市场营销策划能力/落地执行力/数据分析能力;能够根据市场现状和竞争对手制定有效的应对方案。
              </div>
            </div>
          </div>
          <div class="cardlist">
            <div class="names">电商平台店长</div>
            <div class="descibe">职位描述</div>
            <div class="desclist">
              <div class="details">
                1、制定天猫、京东等自营店铺整体运营策略，形成店铺年度销售及活动计划；
              </div>
              <div class="details">
                2、制定月/周销售活动计划，承接好站外流量，拆解完成目标；
              </div>
              <div class="details">
                3、带领团队(运营、设计、客服、CRM
                等岗位)完成目标，实现数字化运营，培养团队成员；
              </div>
              <div class="details">
                4、有较强的数据分析能力，进行网店日常运营数据分析(关注
                UV/PV/销量/跳出率/地域时段/转化率等)，跟踪行业动态，关注竞品店铺产品跟踪，制定价格策略和进销策略；
              </div>
              <div class="details">
                5、负责项目对外沟通，争取平台资源，带领团队落实计划，分析/监控成效。
              </div>
            </div>
            <div class="descibe" style="margin-top: 40px">任职要求</div>
            <div class="desclist">
              <div class="details">
                1、全日制大专及以上学历，市场营销/电子商务等相关专业优先考虑；
              </div>
              <div class="details">
                2、3/5 年以上电商从业经验，独立操盘店铺运营；
              </div>
              <div class="details">
                3、熟悉使用各平台数据分析工具，对互联网营销有较强感知能力。
              </div>
            </div>
          </div>
          <div class="cardlist">
            <div class="names">电商运营助理</div>
            <div class="descibe">职位描述</div>
            <div class="desclist">
              <div class="details">
                1、店铺活动报名、跟进，负责活动商品整理、实时库存管理；
              </div>
              <div class="details">
                2、负责店铺商品标题优化、关键字搜索，进行相关店铺竞品分析；
              </div>
              <div class="details">
                3、参与活动免费资源争取、活动费用预算与控制；
              </div>
              <div class="details">
                4、店铺活动数据统计和分析，参与店铺营销促销活动策划；
              </div>
              <div class="details">
                5、做好内部沟通，确保活动素材按时完成，明确活动规则；
              </div>
              <div class="details">
                6、配合运营主管做好其他工作，后期独立负责店铺运营。
              </div>
            </div>
            <div class="descibe" style="margin-top: 40px">任职要求</div>
            <div class="desclist">
              <div class="details">
                1、本科及以上学历，电子商务、市场营销等专业优先;
              </div>
              <div class="details">
                2、对电子商务感兴趣，对数据敏感，擅于从数据中找出问题并提出解决方法;
              </div>
              <div class="details">
                3、能承受工作压力，自驱力强，有团队合作精神。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tuiguan">
      <div class="operateBox">
        <div class="operateTitle">{{ $t("lang.joinUs.extension.title") }}</div>
        <div class="operatelist">
          <div class="cardlist">
            <div class="names">
              {{ $t("lang.joinUs.extension.itemOne.title") }}
            </div>
            <div class="descibe">{{ $t("lang.joinUs.descibe") }}</div>
            <div class="desclist">
              <div class="details">
                {{ $t("lang.joinUs.extension.itemOne.desc.descOne") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemOne.desc.descTwo") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemOne.desc.descThree") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemOne.desc.descFour") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemOne.desc.descFive") }}
              </div>
            </div>
            <div class="descibe" style="margin-top: 40px">
              {{ $t("lang.joinUs.requirement") }}
            </div>
            <div class="desclist">
              <div class="details">
                {{ $t("lang.joinUs.extension.itemOne.require.descOne") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemOne.require.descTwo") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemOne.require.descThree") }}
              </div>
            </div>
          </div>
          <div class="cardlist">
            <div class="names">
              {{ $t("lang.joinUs.extension.itemTwo.title") }}
            </div>
            <div class="descibe">{{ $t("lang.joinUs.descibe") }}</div>
            <div class="desclist">
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.desc.descOne") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.desc.descTwo") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.desc.descThree") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.desc.descFour") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.desc.descFive") }}
              </div>
            </div>
            <div class="descibe" style="margin-top: 40px">
              {{ $t("lang.joinUs.requirement") }}
            </div>
            <div class="desclist">
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.require.descOne") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.require.descTwo") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.require.descThree") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.require.descFour") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.require.descFive") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.require.descSix") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemTwo.require.descSeven") }}
              </div>
            </div>
          </div>
          <div
            class="cardlist"
            :style="{ display: $i18n.locale === 'en-US' ? 'none' : 'block' }"
          >
            <div class="names">CPC推广</div>
            <div class="descibe">职位描述</div>
            <div class="desclist">
              <div class="details">1、Amazon-CPC投放和数据监控；</div>
              <div class="details">2、回答销售关于站内广告的问题；</div>
              <div class="details">3、站内广告数据统计和分析；</div>
              <div class="details">4、领导安排的其他工作。</div>
            </div>
            <div class="descibe" style="margin-top: 40px">任职要求</div>
            <div class="desclist">
              <div class="details">
                1、英语四级以上，会熟练操作常用的办公软件；
              </div>
              <div class="details">
                2、擅长沟通，主动性强，有一定的抗压能力；
              </div>
              <div class="details">
                3、有较强的数据分析能力，对数据变化敏感并及时做出反应；
              </div>
              <div class="details">4、有其他平台广告投放经验优先。</div>
            </div>
          </div>
          <div class="cardlist">
            <div class="names">
              {{ $t("lang.joinUs.extension.itemFour.title") }}
            </div>
            <div class="descibe">{{ $t("lang.joinUs.descibe") }}</div>
            <div class="desclist">
              <div class="details">
                {{ $t("lang.joinUs.extension.itemFour.desc.descOne") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemFour.desc.descTwo") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemFour.desc.descThree") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemFour.desc.descFour") }}
              </div>
            </div>
            <div class="descibe" style="margin-top: 40px">
              {{ $t("lang.joinUs.requirement") }}
            </div>
            <div class="desclist">
              <div class="details">
                {{ $t("lang.joinUs.extension.itemFour.require.descOne") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemFour.require.descTwo") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemFour.require.descThree") }}
              </div>
              <div class="details">
                {{ $t("lang.joinUs.extension.itemFour.require.descFour") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mobileFooter />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
export default {
  name: "joinUs",
  components: {
    mobileHeader,
    mobileFooter,
  },
};
</script>

<style scoped lang="scss">
.joinBoxs {
  .joinbanner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }
    .bannerTitleB {
      font-family: "English-Bold", serif;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .joincontain {
    width: 100%;
    background: #ffffff;
    padding-bottom: 50px;
    .joincontent {
      padding: 0 25px;
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      align-items: center;
      .joinLeft {
        display: flex;
        flex-direction: column;
        .joinTitle {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .joinRight {
        margin-top: 10px;
        .joinPart {
          margin-top: 40px;
          .joinName {
            color: #444c52;
            font-size: 20px;
            font-weight: bold;
          }
          .joinP {
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
    }
  }
  .joindetail {
    width: 100%;
    background: #f6f6f6;
    padding: 40px 25px 50px;
    box-sizing: border-box;
    .operateBox {
      margin: 0 auto;
      .operateTitle {
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .operatelist {
        .cardlist {
          background: #ffffff;
          margin-top: 30px;
          padding: 10px 30px 30px;
          .names {
            font-weight: bold;
            font-size: 20px;
            margin-top: 20px;
          }
          .descibe {
            font-size: 16px;
            margin-top: 13px;
            margin-bottom: 25px;
            font-weight: bold;
          }
          .desclist {
            margin-top: 20px;
            .details {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  .tuiguan {
    width: 100%;
    background: #ffffff;
    padding: 40px 25px 50px;
    box-sizing: border-box;
    .operateBox {
      margin: 0 auto;
      .operateTitle {
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .operatelist {
        .cardlist {
          background: #f6f6f6;
          margin-top: 30px;
          padding: 10px 30px 30px;
          .names {
            font-weight: bold;
            font-size: 20px;
            margin-top: 20px;
          }
          .descibe {
            font-size: 16px;
            margin-top: 13px;
            margin-bottom: 25px;
            font-weight: bold;
          }
          .desclist {
            margin-top: 20px;
            .details {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
